import defaultTrackWave from '@/assets/global/track_wave_default.png';

const imageDomainUrl = process.env.VUE_APP_IMAGE_DOMAIN;

const trackWaveSource = (src, defaultImg) => {
  if (!src) {
    return defaultImg || defaultTrackWave;
  }

  if (src.includes('http')) {
    return src;
  }

  return `${imageDomainUrl}${escape(src)}`;
};

export default trackWaveSource;
